<template>
  <div>
    <!-- DESKTOP -->
    <div class="pl-8 pr-8 ml-3 mr-3 mb-xl-security">
      <v-row justify="center">
        <v-col class="mt-14 pl-0 pr-0 text-left" cols="12">
          <h1 class="security__title">Seguridad</h1>
        </v-col>
        <v-col cols="12">
          <v-row align="center">
            <template v-for="data in listActionsecurity">
              <v-col
                v-if="data.permisson"
                class="pl-0 pr-6 security__col-card"
                :key="data.id"
                cols="2"
              >
                <v-card outlined elevation="0" class="security__card">
                  <v-card-title>
                    <v-layout justify-center>
                      <img width="100" :src="data.icon" />
                    </v-layout>
                  </v-card-title>
                  <v-card-text style="height: 80px" class="text-left">
                    <p class="title-security">{{ data.title }}</p>
                    <p class="text-security">{{ data.description }}</p>
                  </v-card-text>
                  <v-card-actions class="pt-0 pa-xl-1 pt-xl-5 mt-0 mb-3 mt-3">
                    <v-hover v-slot="{ hover }">
                      <v-btn
                        @click="go(data)"
                        block
                        rounded
                        style="color: #fff"
                        :style="{
                          'background-color': hover ? '#324CA1' : '#466BE3',
                        }"
                        class="pa-xl-0 mt-10"
                      >
                        {{ data.btnName }}
                      </v-btn>
                    </v-hover>
                  </v-card-actions>
                </v-card>
              </v-col>
            </template>
          </v-row>
        </v-col>
      </v-row>
    </div>
    <!-- MOBILE -->
    <div class="mb-sm-security">
      <!-- <v-row justify="center" class="ml-0 mr-0 px-3 my-4 pb-8">
        <v-col style="text-align: left" cols="12" class="py-4 px-0 index-f-sm">
          <div class="d-flex">
            <v-btn @click="$router.go(-1)" x-small class="mt-0 mb-2" fab icon>
              <img width="15" src="../../assets/icon/navigate_before.png" />
            </v-btn>
            <p class="text-title-1">
              <label
                style="padding-top: 5%"
                class="text-apps-blue-sm txt-capitalize"
              >
                {{ name }},
              </label>
              <label style="padding-top: 5%" class="text-apps-blue-sm">
                acá encontrará
              </label>
            </p>
          </div>
          <p style="text-align: left" class="text-apps-sm mb-1 ml-9">
            Información detallada de su nómina
          </p>
        </v-col>
        <v-col cols="12" class="py-0 px-0">
          <template v-for="data in listActionsecurity">
            <v-card
              v-if="data.permisson && data.mobileVersion"
              :key="data.id"
              class="requests-card-security pb-1 pt-1"
            >
              <v-col
                style="text-align: initial"
                class="pl-3 pr-0 d-flex align-center"
                :key="data.id"
                cols="3"
              >
                <v-img width="55" :src="data.icon"></v-img>
              </v-col>
              <v-col
                style="text-align: initial"
                :key="data.id"
                cols="6"
                class="d-flex align-center"
              >
                <p class="title-security pb-0 mb-0 pt-1">
                  {{ data.title }}
                </p>
              </v-col>
              <v-col :key="data.id" cols="3" class="d-flex align-center">
                <v-btn
                  small
                  rounded
                  dark
                  @click="go(data)"
                  color="#466be3"
                  class="mt-3"
                  >ver</v-btn
                >
              </v-col>
            </v-card>
          </template>
        </v-col>
      </v-row> -->
    </div>

    <v-dialog
      style="border-radius: 15px"
      max-width="600"
      v-model="modal.searchUser"
    >
      <v-card>
        <v-card-title class="pt-0 pb-1">
          <p class="text-title-xl pt-8 pb-1">BÚSQUEDA DE USUARIO</p>
          <v-btn
            @click="modal.searchUser = false"
            color="#466BE3"
            icon
            small
            style="display: flex; left: 255px"
          >
            <v-icon> mdi-close </v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text class="px-10">
          <v-autocomplete
            prepend-inner-icon="mdi-account-outline"
            color="#466be3"
            v-model="selectUser"
            :items="listUserAll"
            item-text="user"
            return-object
            label="Nombre - número de cédula - usuario de red"
            :rules="[(v) => !!v || 'Este campo es requerido']"
          >
            <template v-slot:selection="data">
              <v-chip
                small
                dark
                v-bind="data.attrs"
                :input-value="data.selected"
                color="#466be3"
              >
                {{ data.item.user }}
              </v-chip>
            </template>
          </v-autocomplete>
          <!-- 
          <v-divider></v-divider> -->

          <v-alert
            v-if="selectUser.full_name == ''"
            text
            type="info"
            class="mt-3"
          >
            Seleccione un usuario
          </v-alert>

          <v-row v-else class="mt-3" align="center">
            <v-col cols="4">
              <img
                style="
                  cursor: pointer;
                  border-radius: 50%;
                  border: 2px solid;
                  border-color: #466be3;
                "
                class="security__img-profile"
                :src="selectUser.img"
              />
            </v-col>
            <v-col class="text-left" cols="8">
              <label class="sidebar__text-label"> Nombre completo </label>
              <p class="mb-0">{{ selectUser.full_name }}</p>
              <v-divider class="mt-1 pb-1"></v-divider>

              <label class="sidebar__text-label"> Número de documento </label>
              <p class="mb-0">{{ selectUser.document_number }}</p>
              <v-divider class="mt-1 pb-1"></v-divider>

              <label class="sidebar__text-label"> Usuario de red </label>
              <p class="mb-0">{{ selectUser.nickname }}</p>
              <v-divider class="mt-1 pb-1"></v-divider>
            </v-col>
            <v-col class="text-left" cols="12">
              <label class="sidebar__text-label"> Cargo </label>
              <p class="mb-0">{{ selectUser.profile.name }}</p>
              <v-divider class="mt-1 pb-1"></v-divider>

              <label class="sidebar__text-label"> Campaña </label>
              <p class="mb-0">{{ selectUser.campaign }}</p>
              <v-divider class="mt-1 pb-1"></v-divider>

              <label class="sidebar__text-label"> Centro de costos </label>
              <p class="mb-0">{{ selectUser.cc }}</p>
              <v-divider class="mt-1 pb-1"></v-divider>

              <label class="sidebar__text-label"> Subcentro de costos </label>
              <p class="mb-0">{{ selectUser.cod_scc }}</p>
              <v-divider class="mt-1 pb-1"></v-divider>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>

    <Alert
      :open="message.dialog"
      :text="message.sms"
      :title="message.title"
      :type="message.type"
      :redirect="message.redirect"
      @close="message.dialog = false"
    >
    </Alert>

    <v-overlay :value="loadingAll">
      <v-progress-circular :size="120" color="#466BE3" indeterminate>
        Cargando...
      </v-progress-circular>
    </v-overlay>
  </div>
</template>
<script>
import Api from "@/utils/api";
import Alert from "../../components/Alert.vue";
export default {
  components: {
    Alert,
  },
  data() {
    return {
      origin: window.location.origin,
      loadingAll: false,
      infoToken: {
        document: "",
      },
      name: "",
      apps: [],
      modal: {
        searchUser: false,
      },
      listUserAll: [],
      selectUser: {
        full_name: "",
        img: "",
        document_number: "",
        cc: "",
        cod_scc: "",
        campaign: "",
        profile: {
          name: "",
        },
      },
      listActionsecurity: [
        {
          mobileVersion: true,
          icon: origin + "/icon/user-search-lg.svg",
          description:
            "Busque todos los colaboradores activos.",
          title: "Buscar colaboradores",
          redirect: "searchSecurity",
          externalRedirect: false,
          permisson: false,
          btnName: "Buscar",
          id: 1,
        },
        {
          mobileVersion: true,
          icon: origin + "/icon/alarm.svg",
          description:
            "Envíe notidicaciones de usuarios que tienen algún tipo de ausencia.",
          title: "Colaboradores con ausencias",
          redirect: "securityAbsences",
          externalRedirect: false,
          permisson: false,
          btnName: "Ver lista",
          id: 2,
        },
      ],
      name: "",
      token: "",
      message: {
        dialog: false,
        title: "",
        sms: "",
        type: "",
        redirect: "",
      },
    };
  },
  computed: {
    getAllApps() {
      let allApps = this.$store.getters.getApps;
      if (allApps.message) {
        this.getApps(allApps.data);
      } else {
        this.loadingAll = true;
      }
    },
    getAllUsersNickName(){
      let AllUser = this.$store.getters.getAllUsersNickName;
      console.log(AllUser)
      this.getUser(AllUser);
    }
  },
  methods: {
    getToken() {
      var token = localStorage.getItem("token");
      var tokenb64 = localStorage.getItem("tokenB64");
      this.token = tokenb64;
      var div = token.split(".");
      if (div.length >= 2) {
        this.infoToken = JSON.parse(atob(div[1]));
        var nameList = this.infoToken.fullName.split(" ");

        if (nameList.length > 1) {
          if (nameList[0] != "") {
            this.name = nameList[0];
          } else {
            this.name = nameList[1];
          }
        } else {
          this.name = "";
        }
      } else {
        this.infoToken = {
          document: "",
        };
      }
    },
    getApps(allApps) {
      if (allApps && allApps.length !== 0) {
        let end = allApps.length - 1;
        for (let i = 0; i < allApps.length; i++) {
          if (
            allApps[i].es_un_modulo == "true" &&
            (allApps[i]._ID == "315" ||
              allApps[i]._ID == "316" ||
              allApps[i]._ID == "317" ||
              allApps[i]._ID == "318" ||
              allApps[i]._ID == "326" ||
              allApps[i]._ID == "327" ||
              allApps[i]._ID == "335")
          ) {
            for (let j = 0; j < this.listActionsecurity.length; j++) {
              if (allApps[i]._ID == "315") {
                // Director
                if (
                  this.listActionsecurity[j].id == 1 ||
                  this.listActionsecurity[j].id == 3 ||
                  this.listActionsecurity[j].id == 4
                ) {
                  this.listActionsecurity[j].permisson = true;
                }
              } else if (allApps[i]._ID == "318") {
                // TMK
                if (this.listActionsecurity[j].id == 2 ) {
                  this.listActionsecurity[j].permisson = true;
                }
              } else if (allApps[i]._ID == "335") {
                // TMK
                if (this.listActionsecurity[j].id == 1 ||
                  this.listActionsecurity[j].id == 2) {
                  this.listActionsecurity[j].permisson = true;
                }
              }else if (allApps[i]._ID == "317") {
                // Coordinador
                if (
                  this.listActionsecurity[j].id == 2 ||
                  this.listActionsecurity[j].id == 3
                ) {
                  this.listActionsecurity[j].permisson = true;
                }
              } else if (allApps[i]._ID == "316") {
                // Workforce
                if (this.listActionsecurity[j].id == 4) {
                  this.listActionsecurity[j].permisson = true;
                }
              } else if (allApps[i]._ID == "326") {
                if (this.listActionsecurity[j].id == 7) {
                  this.listActionsecurity[j].permisson = true;
                }
              } else if (allApps[i]._ID == "327") {
                if (this.listActionsecurity[j].id == 8) {
                  this.listActionsecurity[j].permisson = true;
                }
              }
            }
          }
          if (i == end) {
            this.loadingAll = false;
          }
        }
      }
    },
    go(data) {
      if(data.redirect == 'searchSecurity'){
        this.modal.searchUser = true;
        return false;
      }
      if (data.externalRedirect) {
        let newURL = document.createElement("a");
        newURL.id = "id-" + Math.floor(Math.random() * (9999999 - 500) + 500);
        newURL.href = data.redirect;
        newURL.target = "_blank";
        document.body.appendChild(newURL);
        newURL.click();
      } else {
        if (data.redirect === "commissionsSign") {
        } else {
          this.$router.push({ name: data.redirect });
        }
      }
    },
    getUser(allUser){
      this.listUserAll = allUser;
    }
  },
  created() {
    this.getToken();
  },
  watch: {
    getAllApps: function (newCount, oldCount) {
      console.log("");
    },
    getAllUsersNickName: function (newCount, oldCount) {
      console.log("");
    },
    selectUser: function (n) {
      if (!n) {
        this.selectUser = {
          full_name: "",
          img: "",
          document_number: "",
          cc: "",
          cod_scc: "",
          campaign: "",
          profile: {
            name: "",
          },
        };
      }
    },
  },
};
</script>
<style lang="less" scoped>
@import "./../../assets/css/main.less";

.navidad-text{
  display: flex;
}
.mb-xl-security {
  display: block;
}
.mb-sm-security {
  display: none;
}

.security__img-profile {
  border-radius: 104px;
  position: static;
  height: 120px !important;
  width: 120px !important;
}

.title-security {
  font-family: "RobotoRegular";
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 25px;
  text-align: center;
  color: #353535;
}
.text-security {
  font-family: "RobotoRegular";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  color: #626262;
  text-align: center !important;
}

.text-apps-blue-sm {
  font-weight: 300;
  font-size: 25px;
  line-height: 30px;
  color: #466be3;
}
.text-apps-sm {
  font-weight: 600;
  font-size: 16px;
  line-height: 18px;
  color: #626262;
  font-family: "RobotoRegular";
}

.requests-card-security {
  margin-top: 20px;
  margin-bottom: 20px;
  display: flex;
  width: 100%;
  height: 100px;
  padding-top: 15px;
  padding-bottom: 15px;
  padding-right: 10px;
  border-radius: 10px !important;
  border: 1px solid #466be3 !important;
  box-shadow: 0px 2px 4px rgba(62, 123, 250, 0.4),
    0px 8px 16px rgba(62, 123, 250, 0.16) !important;
}

.request-project-sign {
  color: #466be3;
  font-family: "RobotoMedium" !important;
  font-weight: 700 !important;
  word-break: normal;
  text-align: center;
  font-size: 2rem !important;
}

.request-txt-project-sign {
  font-family: "RobotoRegular";
  color: #898989 !important;
  font-weight: 300;
  font-size: 1rem;
}

.security__title {
  font-family: "RobotoRegular";
  font-style: normal;
  font-weight: 700;
  font-size: 50px;
  line-height: 59px;
  color: #353535;
}

.security__col-card {
  max-width: 280px !important;
}

.security__card {
  border-radius: 15px !important;
  width: 280px;
}

.security__card:hover {
  box-shadow: 0px 2px 4px rgba(62, 123, 250, 0.4),
    0px 8px 16px rgba(62, 123, 250, 0.16) !important;
  border-radius: 10px !important;
}

/* LAPTOP 4k */

@media (max-width: 1900px) {
}

/* LAPTOP LARGE */

@media (max-width: 1600px) {
}

/* LAPTOP LARGE */

@media (max-width: 1400px) {
}

/* LAPTOP NORMAL */

@media (max-width: 1200px) {
}

/* TABLET */

@media (max-width: 1023px) {
  .title-security {
    font-size: 18px;
    font-family: "RobotoRegular";
    text-align: left;
  }
  .mb-xl-security {
    display: none;
  }
  .mb-sm-security {
    display: block;
    text-align: initial;
  }
}

/* MOBILE L*/
@media (max-width: 767px) {
  .title-security {
    font-size: 18px;
    font-family: "RobotoRegular";
    text-align: left;
  }
  .mb-xl-security {
    display: none;
  }
  .mb-sm-security {
    display: block;
    text-align: initial;
  }
}

/* MOBILE M */
@media (max-width: 375px) {
  .title-security {
    font-size: 18px;
    font-family: "RobotoRegular";
    text-align: left;
  }
  .mb-xl-security {
    display: none;
  }
  .mb-sm-security {
    display: block;
    text-align: initial;
  }
}

/* MOBILE S */
@media (max-width: 320px) {
  .title-security {
    font-size: 18px;
    font-family: "RobotoRegular";
    text-align: left;
  }

  .mb-xl-security {
    display: none;
  }
  .mb-sm-security {
    display: block;
    text-align: initial;
  }
}
</style>
